import React from "react";
import PropTypes from "prop-types";
import TaskActionButton from "../../buttons/taskActionButton";
import { CardActions } from "@mui/material";
import BaseTaskCard from "../baseTaskCard";
import { formatDate } from "@sse-digital-services/design-system";
import useMyLearningCourses from "../../../services/apiUtils/hooks/useMyLearningCourses";

const LMSTaskCard = ({ id, isDashboard }) => {
    const { coursesList } = useMyLearningCourses();
    const course = coursesList.find((course) => course.id === id);

    if (!course) return null;

    return (
        <BaseTaskCard
            key={course.id}
            title="Training Due"
            fields={[
                { field: "Course", value: course.id },
                ...(course.dueDate
                    ? [
                          {
                              field: "Due Date",
                              value: formatDate(course.dueDate),
                          },
                      ]
                    : []),
            ]}
            isDashboard={isDashboard}
        >
            <CardActions sx={{ px: 0 }}>
                <TaskActionButton
                    mode="neutral"
                    action="View in My Learning"
                    url={`https://${course.url}`}
                />
            </CardActions>
        </BaseTaskCard>
    );
};

LMSTaskCard.propTypes = {
    id: PropTypes.string.isRequired,
    isDashboard: PropTypes.bool,
};

export default LMSTaskCard;
